import { useMutation } from '@tanstack/react-query'
import http from '@services/HTTP'
import type { AuthResponse } from './useSignUp'

type ExchangeBody = {
  provider: string
  accessToken: string
  expires: string
}

const useExchange = () => {
  return useMutation<AuthResponse, { message: string }, ExchangeBody>({
    mutationKey: ['exchange'],
    mutationFn: async data => await http.post('/authenticate/sso/exchange', data)
  })
}

export default useExchange
