import { useEffect, useRef } from 'react'
import { useSearchParams } from 'next/navigation'
import usePostGoogleLogin from './usePostGoogleLogin'
import { useRouter } from 'next/navigation'

export const useGoogleRedirect = () => {
  const searchParams = useSearchParams()
  const { onSuccess } = usePostGoogleLogin()
  const hasProcessedToken = useRef(false)
  const router = useRouter()

  useEffect(() => {
    const handleGoogleRedirect = async () => {
      const googleAccessToken = searchParams.get('google_access_token')
      const code = searchParams.get('code')
      const state = searchParams.get('state')
      const redirectUrl = decodeURIComponent(state)
      
      if (googleAccessToken && !hasProcessedToken.current) {
        hasProcessedToken.current = true
        
        const tokenData = {
          access_token: googleAccessToken,
          expires_in: 3600,
          token_type: 'Bearer',
          prompt: 'consent',
          scope: 'email profile'
        }
        
        onSuccess(tokenData)
      }
      if(code && state) {
        // Exchange code for tokens
        const response = await fetch('https://oauth2.googleapis.com/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            code,
            client_id: process.env.GOOGLE_CLIENT_ID,
            client_secret: process.env.GOOGLE_CLIENT_SECRET,
            redirect_uri: new URL(redirectUrl).origin,
            grant_type: 'authorization_code'
          })
        })
        const data = await response.json()
        if (data.error) {
          const errorUrl = new URL(redirectUrl)
          errorUrl.searchParams.set('google_error', data.error)
          router.push(errorUrl.toString())
        } else {
          // Refresh token will only be available on first login
          const { access_token, refresh_token } = data
          const successUrl = new URL(redirectUrl)
          successUrl.searchParams.set('google_access_token', access_token)
          router.push(successUrl.toString())
        }
      }
    }
    handleGoogleRedirect()
  }, [searchParams, onSuccess])
} 