import http from '@services/HTTP'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { safeStorage } from '@utils/storage'
import { useAuthState } from 'store/auth'
import { useCheckoutStore } from 'store/checkout'

export const useGetUser = () => {
  const isLogged = useAuthState(st => st.isLogged)

  return {
    ...useQuery<User>({
      queryKey: ['user'],
      enabled: isLogged,
      queryFn: getUser
    }),
    isLogged
  }
}

export const useGetUserSuspense = () =>
  useSuspenseQuery<User>({
    queryKey: ['user'],
    queryFn: getUser
  })

const getUser = async () => {
  useCheckoutStore.getState().syncCart()
  const data = await http.get<User, User>('/user')
  data.phone = data.phone.replace('+44', '0')
  safeStorage.setItem('user', JSON.stringify(data))
  return data
}

export default useGetUser
