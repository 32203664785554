'use client'
import { useState, useEffect } from 'react'
import useEnhancedEffect from 'hooks/useEnhancedEffect'

interface Props {
  defer?: boolean
}
const NoSsr: FC<Props> = ({ defer, children }) => {
  const [mountedState, setMountedState] = useState(false)

  useEnhancedEffect(() => {
    if (!defer) {
      setMountedState(true)
    }
  }, [defer])

  useEffect(() => {
    if (defer) {
      setMountedState(true)
    }
  }, [defer])

  return <>{mountedState ? children : null}</>
}

export default NoSsr
